.btnWidth {
	background: transparent;
	border: none;
	padding: 0;
	margin-top: 1.2rem;

	height: 4.8rem;
}

.btnConoceMas {
	display: grid;
	align-items: end;
	cursor: pointer;
	text-decoration: none;
	padding: 0;
	position: relative;
	height: 4.8rem;
}

.btnConoceMas__circulo {
	width: 4.8rem;
	height: 4.8rem;
	border-radius: 50%;
	background: var(--violet-dark-web-space);
	transition: transform 0.3s ease-in-out;
	position: absolute;
}

.btnConoceMas__circulo img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.btnConoceMas p {
	font-family: "Akzidenz-Grotesk Pro Ext", sans-serif;
	color: #000000;
	font-size: 1.8rem;
	font-weight: 400;
	transition: all 0.01s ease-in-out;
	display: flex;
	justify-content: start;
	align-items: center;
	margin-left: 4.8rem;
	width: 13.3rem;
	height: 4.8rem;
	position: absolute;
	padding-left: 1.2rem;
}

.btnConoceMas:active {
	transform: scale(1.05);
}

@media (min-width: 1024px) {
	.btnWidth {
		width: fit-content;
		background: transparent;
		border: none;
		padding: 0;
		margin-top: 5rem;
	}

	.btnConoceMas {
		width: 30.1rem;
		height: 10rem;
		display: flex;
		align-items: end;
		cursor: pointer;
		text-decoration: none;
		background: none;
		border: none;
		padding: 0;
		position: relative;
	}

	.btnConoceMas__circulo {
		width: 10rem;
		height: 10rem;
		border-radius: 50%;
		background: var(--violet-dark-web-space);
		position: absolute;
		transition: transform 0.3s ease-in-out;
	}

	.btnConoceMas__circulo img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.btnConoceMas p {
		font-family: "Akzidenz-Grotesk Pro Regular", sans-serif;
		color: #000000;
		font-size: 2.4rem;
		font-weight: 400;
		height: 100%;
		transition: all 0.3s ease-in-out;
		display: grid;
		justify-content: start;
		align-items: center;
		margin-left: 12.4rem;
	}

	.btnConoceMas:hover .btnConoceMas__circulo {
		transform: rotate(45deg);
	}

	.btnConoceMas:hover p {
		font-size: 2.8rem;
	}
}
