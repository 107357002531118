.btnWidth {
  background: none;
  border: none;
  height: 4.8rem;
  margin-top: 1.2rem;
  padding: 0;
}

.btnConoceMas {
  cursor: pointer;
  align-items: end;
  height: 4.8rem;
  padding: 0;
  text-decoration: none;
  display: grid;
  position: relative;
}

.btnConoceMas__circulo {
  background: var(--violet-dark-web-space);
  border-radius: 50%;
  width: 4.8rem;
  height: 4.8rem;
  transition: transform .3s ease-in-out;
  position: absolute;
}

.btnConoceMas__circulo img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btnConoceMas p {
  color: #000;
  justify-content: start;
  align-items: center;
  width: 13.3rem;
  height: 4.8rem;
  margin-left: 4.8rem;
  padding-left: 1.2rem;
  font-family: Akzidenz-Grotesk Pro Ext, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  transition: all 10ms ease-in-out;
  display: flex;
  position: absolute;
}

.btnConoceMas:active {
  transform: scale(1.05);
}

@media (width >= 1024px) {
  .btnWidth {
    background: none;
    border: none;
    width: fit-content;
    margin-top: 5rem;
    padding: 0;
  }

  .btnConoceMas {
    cursor: pointer;
    background: none;
    border: none;
    align-items: end;
    width: 30.1rem;
    height: 10rem;
    padding: 0;
    text-decoration: none;
    display: flex;
    position: relative;
  }

  .btnConoceMas__circulo {
    background: var(--violet-dark-web-space);
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    transition: transform .3s ease-in-out;
    position: absolute;
  }

  .btnConoceMas__circulo img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .btnConoceMas p {
    color: #000;
    justify-content: start;
    align-items: center;
    height: 100%;
    margin-left: 12.4rem;
    font-family: Akzidenz-Grotesk Pro Regular, sans-serif;
    font-size: 2.4rem;
    font-weight: 400;
    transition: all .3s ease-in-out;
    display: grid;
  }

  .btnConoceMas:hover .btnConoceMas__circulo {
    transform: rotate(45deg);
  }

  .btnConoceMas:hover p {
    font-size: 2.8rem;
  }
}
/*# sourceMappingURL=index.a725d1d3.css.map */
